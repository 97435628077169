import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonIcon from '@material-ui/icons/Person';
import {
    Grid,
    Typography,
    Snackbar
} from '@material-ui/core';
import {
    ModalInfo,
    MaterialInput,
} from '@findep/mf-landings-core'
import Alert from '@material-ui/lab/Alert';
import { useTheme } from '@findep/microfronts-core'
import { navigate } from 'gatsby'

import { TubeService } from '../../services/LandingV4/tube'
import AvisosApp from '../elements/AvisosApp'
import nameCompany from '../../helpers/NameCompany'

export const PureRastreo = ({ avisosApp, open, onClose, company }) => {
    const [tel, setTel] = useState({ tel1: '', tel2: '' })
    const [errorTel, setErrorTel] = useState({ tel1: '', tel2: '' })
    const [cargando, setCargando] = useState(false)
    const [errorService, setErrorService] = useState('')

    const { palette } = useTheme()


    const keyColorCss = css`
        color: ${palette.action.disabled};
    `

    useEffect(() => {
        let error = ''
        const matchError = 'Los números no coinciden'
        if (tel.tel1 && tel.tel2 && tel.tel1 !== tel.tel2) {
            error = matchError
            setErrorTel({ tel1: error, tel2: error })
        } else {
            setErrorTel({ tel1: errorTel.tel1 === matchError ? '' : errorTel.tel1, tel2: errorTel.tel2 === matchError ? '' : errorTel.tel2 })
        }


    }, [tel])

    const handleTelChange = (prop, value) => {
        setTel({ ...tel, [prop]: value })
        setErrorTel({ ...errorTel, [prop]: '' })
    }

    const handleErrorTel = (prop, value) => {
        setErrorTel({ ...errorTel, [prop]: value })
    }


    const logoCss = css`
        margin-top: 0.8em;
        ${keyColorCss}
    `

    const submit = async () => {
        setCargando(true)
        let Tube = new TubeService('')
        Tube.setMobileNumber(tel.tel2)
        try {
            const { data } = await Tube.sendSearch()
            navigate(`/tu-proceso?creditApplicationId=${data.id}`)
        } catch (e) {
            console.error(e)
            if (e.status === 404) {
                setErrorService('Solicitud no encontrada')
            } else {
                setErrorService(`Error al consultar el servicio`)
            }
            setCargando(false)
        }



    }

    // const validaTelefonos

    return (
        <>
            <ModalInfo
                icon={PersonIcon}
                id="rastreo-modal"
                title="Número de rastreo"
                autoClose={false}
                open={open}
                color="primary"
                onChange={(data) => { if (data.value === 'CONTINUAR') { submit() } }}
                buttonJustify="flex-end"
                btnRight={cargando ? 'CARGANDO' : 'CONTINUAR'}
                btnRightDisabled={cargando || !!errorTel.tel1 || !!errorTel.tel2 || !tel.tel1 || !tel.tel2}
                btnRightColor="primary"
                onClose={onClose}
                body={
                    (
                        <>
                            { errorService &&
                                <Snackbar
                                    open={!!errorService}
                                    autoHideDuration={6000}
                                    onClose={() => setErrorService('')}
                                >
                                    <Alert
                                        variant="filled"
                                        severity="warning"
                                        onClose={() => setErrorService('')}
                                    >
                                        {errorService || 'Error al consultar el servicio'}
                                    </Alert>
                                </Snackbar>
                            }
                            <Grid container spacing={2}>
                                <Grid xs={2} item>
                                    <VpnKeyIcon css={logoCss} fontSize="default" />
                                </Grid>
                                <Grid xs={10} item>
                                    <MaterialInput
                                        id="rastreo-telefono1"
                                        type="text"
                                        error={!!errorTel.tel1}
                                        value={tel.tel1}
                                        onError={() => handleErrorTel('tel1', 'Número invalido')}
                                        onChange={(event, data) => handleTelChange('tel1', data.value)}
                                        pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                                        transform={(value) => value.toUpperCase().slice(0, 10)}
                                        accept="0-9"
                                        errorMessage={errorTel.tel1}
                                        fullWidth
                                        label="Número de rastreo"
                                        helperText="Número de rastreo que te fue otorgado"
                                        variant="outlined"
                                        inputmode='numeric'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid xs={2} item>
                                    <VpnKeyIcon css={logoCss} fontSize="default" />
                                </Grid>
                                <Grid xs={10} item>
                                    <MaterialInput
                                        id="rastreo-telefono2"
                                        type="text"
                                        fullWidth
                                        onError={() => handleErrorTel('tel2', 'Número invalido')}
                                        pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                                        accept="0-9"
                                        disabled={!tel.tel1}
                                        onChange={(event, data) => handleTelChange('tel2', data.value)}
                                        transform={(value) => value.toUpperCase().slice(0, 10)}
                                        error={!!errorTel.tel2}
                                        errorMessage={errorTel.tel2}
                                        value={tel.tel2}
                                        label="Repite tu número de rastreo"
                                        helperText="Número de rastreo que te fue otorgado"
                                        variant="outlined"
                                        inputmode='numeric'
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <div>
                                {avisosApp}
                            </div>
                        </>
                    )
                }
            />
        </>
    )
} 



function Rastreo({ open, onClose, company }) {
    const linkCss = css`
        & a:link { 
            text-decoration: none;
            color: inherit;
        } 
    `

    const avisos = (
        <Typography css={linkCss} align="left" variant="caption" display="block">
                                    Al dar click en continuar, aceptas los&nbsp;
        <AvisosApp terminos company={company} id="rastreo-modal-terminos-a" >
                    <Typography color="primary" variant="caption">
                        <strong>
                            términos, condiciones
                </strong>
                    </Typography>
                </AvisosApp>
        &nbsp;y&nbsp;
        <AvisosApp company={company} id="rastreo-modal-aviso-a" >
                    <Typography color="primary" variant="caption">
                        <strong>
                            aviso de privacidad
                </strong>
                    </Typography>
                </AvisosApp>
        &nbsp;de {nameCompany(company).display}.
    </Typography>
    )

    return (
        <PureRastreo open={open} onClose={onClose} company={company} avisosApp={avisos} />
    )
}

export default Rastreo
